<template>
  <div class="about">
    <br /><br /><br /><br />
    <v-row class="text-center">
      <v-col class="mb-4" offset="1" cols="12" sm="10" md="10">
        <iframe
          style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);"
          width="320"
          height="240"
          src="https://charts.mongodb.com/charts-project-0-qwyaz/embed/charts?id=bfb8da14-a898-4f97-903b-d1b23a13d221&maxDataAge=3600&theme=light&autoRefresh=false"
        ></iframe>
        <iframe
          style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);"
          width="320"
          height="240"
          src="https://charts.mongodb.com/charts-project-0-qwyaz/embed/charts?id=e9418ec1-d437-43c9-836e-8496a58ca341&maxDataAge=3600&theme=light&autoRefresh=false"
        ></iframe>
        <iframe
          style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);"
          width="320"
          height="240"
          src="https://charts.mongodb.com/charts-project-0-qwyaz/embed/charts?id=9693e7ba-954a-4d58-81eb-52a1e2d7b030&maxDataAge=3600&theme=light&autoRefresh=false"
        ></iframe>
      </v-col>
      <v-col class="mb-4" offset="1" cols="12" sm="10" md="10">
        <a
          href="https://charts.mongodb.com/charts-project-0-qwyaz/public/dashboards/3baff71c-bcf8-4e15-afef-41bbf3106912"
          target="_blank"
          >Open All Dashboard Items</a
        >
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" bottom top shaped color="blue">
      {{ snackbarMessage }}
      <v-btn color="pink" text timeout="10000" @click="snackbar = false"
        >Close</v-btn
      >
    </v-snackbar>
  </div>
</template>

<script>
// import PlanType1 from "../components/plans/PlanType1";
// import PlanType1 from '../../public/PlanType1.svg';

export default {
  name: "About",
  components: {
    // PlanType1
  },
  metaInfo: {
    title: "About us",
    titleTemplate: "CPC - %s",
    meta: [
      {
        name: `description`,
        content: `About CPC here.`
      }
    ],
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  data() {
    return {
      done1: false,
      done2: false,
      done3: false,
      snackbar: false,
      snackbarMessage: "",
      paths: []
    };
  },
  mounted() {
    this.processNotifications();
  },
  methods: {
    diagonalD(event) {
      this.paths.forEach(el => {
        if (el.id === event.currentTarget.id) {
          el.stroke = "green";
        }
      });
      this.snackbarMessage = `${event.currentTarget.id} Correct Dimensions`;
      this.snackbar = true;
    },
    cornersAdj(event) {
      this.rects.forEach(el => {
        if (el.id === event.currentTarget.id) {
          el.fill = "green";
        }
      });
      this.snackbarMessage = `${event.currentTarget.id} Straight, Square & Plum`;
      this.snackbar = true;
    }
  }
};
</script>

<style scoped>
.corner {
  background-color: orange;
  cursor: pointer;
}
svg {
  height: 80vh;
}
rect:hover {
  cursor: pointer;
}
path:hover {
  cursor: pointer;
}
</style>
